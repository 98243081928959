import React, { useState } from 'react'

// Third party
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay, Navigation } from 'swiper'

// Third party CSS
import 'swiper/css'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Images
import SwiperArrow from 'img/swiperarrow.svg'

// Interface
import { SliderProps } from 'components/flex/Slider/SliderShell'
import { motion } from 'framer-motion'

const Section = styled.section`
  background-color: ${({ theme }) => theme.color.dark};

  & .swiper {
    width: 100% !important;
  }

  & .custom-swiper-button-prev {
    content: url(${SwiperArrow});
    transform: rotate(180deg);
  }

  & .custom-swiper-button-next {
    content: url(${SwiperArrow});
  }

  & .swiper-button-disabled {
    opacity: 0.25;
  }

  .swiper-wrapper {
    justify-content: space-between;
  }

  .swiper-slide {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 5px;

    @media (min-width: 450px) {
      padding-left: 7.5px;
    }
  }
`

const Content = styled(ParseContent)`
  & h2 {
    color: ${({ theme }) => theme.color.light};
  }
`

const Image = styled(Plaatjie)`
  border-radius: 50%;

  @media (min-width: 576px) {
    width: 125px;
    height: 125px;
  }

  @media (max-width: 575px) {
    width: 95px;
    height: 95px;
  }

  @media (max-width: 399px) {
    width: 80px;
    height: 80px;
  }
`

const MotionDiv = styled(motion.div)`
  @media (min-width: 576px) {
    width: 125px;
    height: 125px;
  }

  @media (max-width: 575px) {
    width: 95px;
    height: 95px;

    & > span {
      font-size: ${({ theme }) => theme.font.size.small};
    }
  }

  @media (max-width: 399px) {
    width: 80px;
    height: 80px;

    & > span {
      font-size: 12px;
    }
  }
`

const Slider: React.FC<SliderProps> = ({ fields }) => {
  const [hovered, setHovered] = useState(-1)

  SwiperCore.use([Autoplay, Navigation])

  return (
    <Section>
      <div className="container py-5">
        <div className="row justify-content-center">
          <div className="col-lg-7">
            <Content content={fields.description} />
          </div>
        </div>
        <div className="row justify-content-center mt-5">
          <div className="col-lg-11">
            <div className="d-flex">
              <div
                className="custom-swiper-button-prev me-2 me-sm-3"
                role="button"
              >
                Vorige
              </div>
              <Swiper
                navigation={{
                  prevEl: '.custom-swiper-button-prev',
                  nextEl: '.custom-swiper-button-next',
                }}
                breakpoints={{
                  1: {
                    slidesPerView: 3,
                  },
                  576: {
                    slidesPerView: 3,
                  },
                  768: {
                    slidesPerView: 3,
                  },
                  992: {
                    slidesPerView: 4,
                  },
                  1200: {
                    slidesPerView: 6,
                  },
                }}
              >
                {fields?.slider?.map((slide, index: number) => {
                  const scale = hovered === index

                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <React.Fragment key={index}>
                      <SwiperSlide>
                        <a
                          href={slide?.link?.url}
                          className="swiper-slide d-flex justify-content-center align-items-center"
                        >
                          <MotionDiv
                            onHoverStart={() => setHovered(index)}
                            onHoverEnd={() => setHovered(-1)}
                            animate={scale ? { scale: 1.1 } : { scale: 1.0 }}
                            className="d-flex justify-content-center align-items-center position-relative "
                          >
                            <span
                              className={`position-relative ${
                                slide?.textcolor === 'light'
                                  ? 'text-white'
                                  : 'text-black'
                              }`}
                              style={{ zIndex: 2 }}
                            >
                              {slide?.link?.title}
                            </span>
                            <Image
                              image={slide?.image}
                              alt=""
                              className="position-absolute"
                            />
                          </MotionDiv>
                        </a>
                      </SwiperSlide>
                    </React.Fragment>
                  )
                })}
              </Swiper>
              <div className="custom-swiper-button-next" role="button">
                Volgende
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Slider
